<template>
  <div class="change-status-dialog">
    <el-radio-group v-model="statusForm.status" size="small">
      <el-radio-button v-for="status in changeStatusList" :label="status.id" :key="status.id">
        {{ status.name }}
      </el-radio-button>
    </el-radio-group>
    <div class="footer-wrap">
      <el-button size="small" type="primary" @click="handleConfirmBtn">确认</el-button>
      <el-button size="small" type="info" @click="$emit('closeDialog', false)">取消</el-button>
    </div>
  </div>
</template>

<script>
import { cluesCenterChangeStatusAction } from '@/common/businessManagement.js';
export default {
  name: 'changStatusDialog',
  props: {
    changeStatusList: {
      type: Array,
      default: () => [],
    },
    changeStatusData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    statusForm() {
      return this.changeStatusData;
    },
  },
  methods: {
    // 处理确认按钮
    handleConfirmBtn() {
      let params = {
        ID: this.statusForm.id,
        Status: this.statusForm.status,
      };
      cluesCenterChangeStatusAction(params)
        .then((res) => {
          let { code, msg } = res;
          if (code === 200) {
            this.$message({
              type: 'success',
              message: `${msg}`,
              center: true,
            });
            this.$emit('closeDialog', false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.change-status-dialog {
  text-align: center;

  .el-radio-button {
    margin: 0 20px 20px 0;
    border: 1px solid #dcdfe6;
  }

  .footer-wrap {
    text-align: center;
  }
}
</style>
