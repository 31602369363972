import $http from '@/utils/http.js';
// import dayjs from 'dayjs';
// let formatDate = dayjs(new Date()).format('YYYY-MM-DD');

/**
 * 线索中心列表
 * @param data
 */
export function getCluesCenterDataList(data) {
  return $http.get('/api/clue/index', data, 'loadingDiv');
}

/**
 * 省市区数据
 * @param data
 */
export function postProvincesList(data) {
  return $http.post('/api/areas', data, 'loadingDiv');
}

/**
 * 导出线索中心列表操作
 * @param data
 */
export function downloadCluesCenterListAction(data) {
  // return $http.export('/api/clue/export', data, 'loadingDiv', `线索管理信息列表_${formatDate}.xlsx`);
  // return $http.exports('/api/clue/export', data, 'loadingDiv', `线索管理信息列表_${formatDate}.xlsx`);
  // return $http.exportsTxt('/api/clue/export', data, 'loadingDiv', `线索管理信息列表_${formatDate}.xlsx`);
  return $http.exportsTxt('/api/clue/export', data, 'loadingDiv', `线索管理信息列表.xlsx`);
}

/**
 * 线索中心列表获取线索操作
 * @param data
 */
export function cluesCenterGetCluesAction(data) {
  return $http.post('/api/clue/applyClue', data, 'loadingDiv');
}
export function cluesCenterGetCluesActionS(data) {
  return $http.post('/api/clue/seeClue', data, 'loadingDiv');
}

/**
 * 线索中心列表编辑备注操作
 * @param data
 */
export function cluesCenterEditNoteAction(data) {
  return $http.post('/api/clue/editRemark', data, 'loadingDiv');
}

/**
 * 线索中心列表更改状态操作
 * @param data
 */
export function cluesCenterChangeStatusAction(data) {
  return $http.post('/api/clue/changeStatus', data, 'loadingDiv');
}

/**
 * 线索中心列表删除操作
 * @param data
 */
export function cluesCenterDeleteAction(data) {
  return $http.post('/api/clue/deleteCustom', data, 'loadingDiv');
}
