<template>
  <div class="clues-manage-page">
    <div class="clues-manage-header">
      <div class="header-left">
        <el-cascader
          v-model="cluesForm.cityAddress"
          :options="cityAddressList"
          :props="cityAddressListProps"
          clearable
          size="small"
          placeholder="地区"
        ></el-cascader>
        <el-select size="small" v-model="cluesForm.status" placeholder="状态" clearable>
          <el-option
            v-for="(item, index) in statusList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input
          size="small"
          v-model="cluesForm.searchVal"
          placeholder="搜索"
          clearable
        ></el-input>
        <el-button size="small" type="primary" @click="handleSearch">查询</el-button>
      </div>
      <div class="header-right">
        <!-- <el-button size="small" type="primary" @click="handleExport">导出</el-button> -->
        <!-- <span style="margin: 0 20px; top: 2px">|</span> -->
        <span style="top: 3px; margin-right: 20px">共{{ page.count }}条</span>
      </div>
    </div>
    <div class="clues-manage-table">
      <el-table
        :header-cell-style="{ background: '#F2F2F2' }"
        :data="cluesCenterDataList"
        border
        align="center"
        style="width: 100%"
      >
        <el-table-column
          label="序号"
          width="60px"
          type="index"
          :index="indexMethod"
          align="center"
        ></el-table-column>
        <el-table-column prop="note" label="状态" align="center">
          <template v-slot="scope">
            <div
              :class="[
                scope.row.status === 2
                  ? 'gray-color'
                  : scope.row.status === 7
                  ? 'red-color'
                  : scope.row.status === 3 || scope.row.status === 4
                  ? 'blue-color'
                  : '',
              ]"
            >
              {{ statusName(scope.row) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="customerName" label="客户名称" align="center" width="180">
          <template v-slot="scope">
            <el-button type="text">{{ scope.row.customerName }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="cluesSource" label="线索来源" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" align="center">
          <template v-slot="scope">
            <div>{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="belongsRegion"
          label="所属地区"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column prop="phone" label="手机号" align="center" width="150"></el-table-column>
        <el-table-column
          prop="formTime"
          label="表单填写时间"
          align="center"
          width="160"
        ></el-table-column>
        <el-table-column prop="note" label="备注" align="center" width="180">
          <template v-slot="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.note ? scope.row.note : '/'"
              placement="bottom"
            >
              <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                {{ scope.row.note ? scope.row.note : '/' }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right" width="240">
          <template v-slot="scope">
            <el-button
              @click="handleObtainCluesBtn(scope.row)"
              type="text"
              size="small"
              v-if="scope.row.status === 3 || scope.row.status === 5"
              :disabled="!permissionsAction.apply"
            >
              获取线索
            </el-button>
            <el-button
              @click="handleEditNoteBtn(scope.row)"
              type="text"
              size="small"
              :disabled="!permissionsAction.edit"
            >
              编辑备注
            </el-button>
            <el-button
              @click="handleChangeStatus(scope.row)"
              type="text"
              size="small"
              :disabled="!permissionsAction.change"
            >
              更改状态
            </el-button>
            <el-button
              @click="handleDeleteBtn(scope.row)"
              type="text"
              size="small"
              :disabled="!permissionsAction.delete"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: right; margin-top: 30px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[10, 15, 30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.count"
      ></el-pagination>
    </div>

    <!-- 编辑备注弹窗 -->
    <el-dialog title="编辑备注" :visible.sync="dialogEditNoteVisible" width="30%" center>
      <editNoteDialog
        :editNoteData="editNoteData"
        @closeDialog="closeDialog"
        ref="one"
      ></editNoteDialog>
    </el-dialog>
    <!-- 更改状态弹窗 -->
    <el-dialog title="更改状态" :visible.sync="dialogChangeStatusVisible" width="30%" center>
      <changStatusDialog
        :changeStatusList="changeStatusList"
        :changeStatusData="changeStatusData"
        @closeDialog="closeDialog"
      ></changStatusDialog>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="one">
        {{ wringData }}
      </div>
      <div class="two">
        <el-button size="small" type="primary" @click="goBuy">去购买</el-button>
        <el-button size="small" type="primary" @click="goS">申请线索</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import editNoteDialog from '@/views/cluesManage/components/editNoteDialog.vue';
import changStatusDialog from '@/views/cluesManage/components/changeStatusDialog.vue';
import {
  postProvincesList,
  getCluesCenterDataList,
  // downloadCluesCenterListAction,
  cluesCenterGetCluesAction,
  cluesCenterGetCluesActionS,
  cluesCenterDeleteAction,
} from '@/common/businessManagement.js';
export default {
  name: 'CluesManagePage',
  components: {
    editNoteDialog,
    changStatusDialog,
  },
  data() {
    return {
      cluesForm: {
        // 地址
        cityAddress: [],
        // 状态
        status: '',
        searchVal: '',
      },
      page: {
        page: 1,
        pageSize: 10,
        count: 0,
      },
      // 城市地址列表
      cityAddressList: [],
      cityAddressListProps: {
        value: 'code',
        label: 'name',
        children: 'children',
      },
      // 状态列表
      statusList: [
        {
          id: '',
          name: '全部',
        },
      ],
      // 更改状态列表
      changeStatusList: [],
      // 表格数据
      cluesCenterDataList: [],
      // 编辑备注弹窗
      dialogEditNoteVisible: false,
      editNoteData: {},
      // 更改状态弹窗
      dialogChangeStatusVisible: false,
      changeStatusData: {},
      // 权限操作合集
      permissionsAction: {},
      // 获取线索完成后弹窗
      centerDialogVisible: false,
      wringData: '',
      id: '',
    };
  },
  created() {
    this.init();
  },
  watch: {
    dialogEditNoteVisible() {
      if (this.dialogEditNoteVisible == false) {
        this.$refs.one.editForm.note = '';
      }
    },
  },
  methods: {
    goBuy() {
      this.$message.warning('请联系管理员');
    },
    goS() {
      this.closeDialog(false);
      cluesCenterGetCluesAction({ ID: this.id }).then((res) => {
        let { code, msg } = res;
        if (code === 200) {
          this.$message({
            type: 'success',
            message: `${msg}`,
            center: true,
          });
          this.queryCluesCenterData();
        }
      });
    },
    init() {
      this.queryProvincesData();
      this.queryCluesCenterData();
    },
    showDialog(val) {
      this.dialogEditNoteVisible = val;
      this.dialogChangeStatusVisible = val;
      this.centerDialogVisible = val;
    },
    closeDialog(val) {
      this.dialogEditNoteVisible = val;
      this.dialogChangeStatusVisible = val;
      this.centerDialogVisible = val;
      this.queryCluesCenterData();
    },
    // 查询省市区数据
    queryProvincesData() {
      postProvincesList()
        .then((res) => {
          let { code, Areas } = res;
          if (code === 200) {
            this.cityAddressList = Areas;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    queryCluesCenterData() {
      let province = 0;
      let city = 0;
      let area = 0;
      // 转换Proxy为数组
      let CityAddRess = JSON.parse(JSON.stringify(this.cluesForm.cityAddress));
      if (CityAddRess == null) {
        province = 0;
        city = 0;
        area = 0;
      } else {
        province = CityAddRess[0];
        city = CityAddRess[1];
        area = CityAddRess[2];
      }
      let params = {
        Status: this.cluesForm.status,
        EnterpriseName: this.cluesForm.searchVal,
        Page: this.page.page,
        Len: this.page.pageSize,
        Province: province,
        City: city,
        Area: area,
      };
      this.statusList = [
        {
          id: '',
          name: '全部',
        },
      ];
      // console.log(params);
      getCluesCenterDataList(params)
        .then((res) => {
          let { code, count, ClueInfo, Status, StatusList, auth } = res;
          if (code === 200) {
            this.cluesCenterDataList = ClueInfo.map((item) => {
              return {
                id: item.ID,
                status: item.Status.Status,
                customerName: item.EnterpriseName,
                cluesSource: item.Source,
                name: item.RealName,
                belongsRegion: item.Areas,
                phone: item.Mobile,
                formTime: item.CreateTime,
                note: item.Remark,
              };
            });
            this.statusList = this.statusList.concat(Status);
            this.changeStatusList = StatusList;
            this.page.count = count;
            this.permissionsAction = auth;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 状态名
    statusName(row) {
      return this.statusList.find((val) => val.id === row.status)?.name;
    },
    // 手机号脱敏
    // phoneDesensitization(row) {
    //     let phone = row.phone;
    //     return hideMobile(phone);
    // },
    // 处理查询按钮
    handleSearch() {
      this.page.page = 1;
      this.queryCluesCenterData();
    },
    // 处理导出按钮
    handleExport() {
      if (this.cluesCenterDataList.length === 0) {
        this.$message({
          type: 'success',
          message: '表格暂时无数据',
          center: true,
        });
        return;
      }
      // let province = this.cluesForm.cityAddress[0];
      // let city = this.cluesForm.cityAddress[1];
      // let area = this.cluesForm.cityAddress[2];

      // let _this = this;
      // this.$confirm('确认导出?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      // }).then(() => {
      //     let params = {
      //         Status: this.cluesForm.status,
      //         EnterpriseName: this.cluesForm.searchVal,
      //         Province: province,
      //         City: city,
      //         Area: area
      //     };
      //     // console.log(params);
      //     downloadCluesCenterListAction(params).then(res => {
      //         console.log(res);
      //         _this.$message.success('导出成功');
      //     });
      // });
    },
    // 处理获取线索按钮
    handleObtainCluesBtn(row) {
      let params = {
        ID: row.id,
      };
      this.id = row.id;
      this.$confirm('确认获取线索？', '获取线索', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          cluesCenterGetCluesActionS(params)
            .then((res) => {
              let { code, msg } = res;
              if (code === 200) {
                this.$message({
                  type: 'success',
                  message: `${msg}`,
                  center: true,
                });
                this.queryCluesCenterData();
              }
              if (code === 10012) {
                this.centerDialogVisible = true;
                this.wringData = msg;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: 'success',
            message: '已取消操作!',
            center: true,
          });
        });
    },
    // 处理编辑备注按钮
    handleEditNoteBtn(row) {
      this.editNoteData = {
        id: row.id,
        note: row.note,
      };
      this.dialogEditNoteVisible = true;
    },
    // 处理更改状态按钮
    handleChangeStatus(row) {
      this.changeStatusData = {
        id: row.id,
        status: row.status,
      };
      this.dialogChangeStatusVisible = true;
    },
    // 处理删除按钮
    handleDeleteBtn(row) {
      let params = {
        ID: row.id,
      };
      this.$confirm('是否进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          // console.log(params);
          cluesCenterDeleteAction(params)
            .then((res) => {
              let { code, msg } = res;
              if (code === 200) {
                this.$message({
                  type: 'success',
                  message: `${msg}`,
                  center: true,
                });
                this.queryCluesCenterData();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: 'success',
            message: '已取消操作!',
            center: true,
          });
        });
    },
    // 处理分页
    handleSizeChange(size) {
      this.page.pageSize = size;
      this.queryCluesCenterData();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.queryCluesCenterData();
    },
    indexMethod(index) {
      return this.page.pageSize * (this.page.page - 1) + 1 + index;
    },
  },
};
</script>

<style lang="scss" scoped>
.one,
.two {
  text-align: center;
}

.two {
  margin-top: 50px;
}

.clues-manage-page {
  box-sizing: border-box;
  padding: 25px 20px;
  background-color: #fff;

  .clues-manage-header {
    &::after {
      display: block;
      clear: both;
      content: '';
      height: 0;
      visibility: hidden;
    }

    .header-left {
      float: left;
      width: 700px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      // .el-date-editor,
      // :deep(.el-cascader),
      // .el-select,
      // .el-input {
      //     margin-right: 15px;
      // }

      .el-input {
        width: 180px;
      }

      .el-select {
        width: 180px;
      }
    }

    .header-right {
      float: right;

      span {
        position: relative;
        font-size: 18px;
      }
    }
  }

  .clues-manage-table {
    margin-top: 30px;

    .red-color {
      color: #ff1717;
    }

    .gray-color {
      color: #8e8e8e;
    }

    .blue-color {
      color: #42a9ff;
    }
  }
}
</style>
